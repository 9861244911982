// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import Header from "layouts/profile/components/Header";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import secureLocalStorage from "react-secure-storage";
import { searchAllBanners } from "api_services/BannerService";
import { Alert, Card, CircularProgress, Dialog, Snackbar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MDBadge from "components/MDBadge";
import { deleteBannerById } from "api_services/BannerService";

function Banners() {
  const [banners, setBanners] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [nextPage, setNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const userToken = secureLocalStorage.getItem("custom-token");
  const [bannerID, setBannerID] = useState("");
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [positionState, setPositionState] = useState({
    openTwo: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openTwo } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, openTwo: false });
  };

  const handleOpen = (id) => {
    setBannerID(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getBannerList();
  };

  async function deleteBanner() {
    setDeleting(true);
    const response = await deleteBannerById(bannerID, userToken);

    if (response == "ok") {
      getBannerList();
      setOpen(false);
      setDeleting(false);
    } else {
      setOpen(false);
      setDeleting(false);
      setPositionState({ openTwo: true, vertical: "top", horizontal: "center" });
      //console.log(response);
    }
  }

  async function getBannerList() {
    const response = await searchAllBanners(userToken, page, searchData, 10);
    if (response !== null) {
      setIsLoading(false);
      setBanners(response.searchResults);
      setNextPage(response.morePages);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginate = (step) => {
    goToTop();
    const pageNum = page + step;
    if (pageNum >= 0) {
      setPage(pageNum);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchData.length >= 3 || searchData == "") {
        getBannerList();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [page, searchData]);

  const isExpired = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getTime() >= date2.getTime();
  };

  const renderBanners = banners.map((banner) => {
    const currentDate = new Date();
    let displayDate;
    let displayDateText;
    let published = false;
    let expired = false;
    let scheduled = false;
    if (banner.dateToPublish <= currentDate.getTime()) {
      if (banner.expiryDate < currentDate.getTime()) {
        expired = true;
        displayDate = banner.expiryDate;
        displayDateText = "Expired On ";
      } else {
        published = true;
        displayDate = banner.expiryDate;
        displayDateText = "Expiring On ";
      }
    } else {
      scheduled = true;
      displayDate = banner.dateToPublish;
      displayDateText = "To Be Published On ";
    }
    return (
      <Card sx={{ mx: 0, mb: 2 }} key={banner.id}>
        <MDBox
          display="flex"
          alignItems="center"
          p={2}
          sx={{
            mb: { xs: -4, md: -4, lg: -7 },
            mr: { xs: 3, md: 0 },
            justifyContent: { xs: "space-between", lg: "end" },
          }}
        >
          <MDTypography variant="caption" color="text" ml={0} mr={10}>
            {displayDateText +
              new Date(displayDate).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
          </MDTypography>

          <MDBox display="flex" alignItems="center">
            <EditIcon
              color="black"
              fontSize="small"
              sx={{ cursor: "pointer", right: 53, position: "absolute" }}
              onClick={() => navigate(`/update-banner/${banner.id}`)}
            />
            <DeleteIcon
              color="error"
              fontSize="medium"
              sx={{ cursor: "pointer", mr: 2, right: 0, position: "absolute" }}
              onClick={() => {
                handleOpen(banner.id, 1);
              }}
            />
          </MDBox>
        </MDBox>
        <MDBox
          py={2}
          pl={1}
          pr={2}
          mt={1}
          display="flex"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "start", md: "center" },
          }}
        >
          <Grid item ml={1} xs={12} md={2}>
            <img
              width="100%"
              height="100%"
              src={!banner.bannerImage ? "./placeholder.jpg" : banner.bannerImage}
              style={{ borderRadius: "17px", objectFit: "cover" }}
            />
          </Grid>
          <MDBox ml={2}>
            <MDBox display="flex" flexDirection="column">
              <MDBox display="flex">
                <MDTypography variant="button" fontWeight="medium" color="black" mt={0}>
                  {banner.title}
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={scheduled ? "grey[700]" : expired ? "error" : "primary"}
                  mt={0}
                  ml={2}
                >
                  {scheduled ? "SCHEDULED" : expired ? "EXPIRED" : published ? "PUBLISHED" : null}
                </MDTypography>
              </MDBox>
              {/* <MDTypography variant="caption" color="black" fontWeight="none" mt={0.5}>
                {banner.description}
              </MDTypography> */}
              <MDBox
                display="flex"
                justifyContent="start"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "start", md: "center" },
                }}
              >
                {banner.cities.map((name) => (
                  <MDBadge
                    badgeContent={name}
                    color="info"
                    variant="gradient"
                    size="sm"
                    sx={{
                      ml: { xs: -1, md: 0 },
                      my: { xs: 1, md: 1 },
                    }}
                  />
                ))}
                {banner.countries.map((name) => (
                  <MDBadge
                    badgeContent={name}
                    color="primaryDark"
                    variant="gradient"
                    size="sm"
                    sx={{
                      ml: { xs: -1, md: 0 },
                      my: { xs: 1, md: 1 },
                    }}
                  />
                ))}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Card sx={{ marginBottom: 3 }}>
        <MDBox
          sx={{
            p: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          component="form"
          role="form"
          onSubmit={handleSubmit}
        >
          <MDInput
            sx={{ mr: 2, pl: 0, flex: 1 }}
            placeholder="Search by Banner Title"
            onChange={(e) => setSearchData(e.target.value)}
          />
          <MDButton size="medium" color="primary" type="submit">
            Search
          </MDButton>
        </MDBox>
      </Card>
      {isLoading ? (
        // Render a spinner or loading indicator
        <Stack spacing={3}>
          <MDBox display="flex">
            <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </MDBox>
          <MDBox display="flex">
            <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </MDBox>
          <Skeleton variant="rectangular" width={"100%"} height={70} />
          <Skeleton variant="rectangular" width={"100%"} height={40} />
          <Skeleton variant="rectangular" width={"100%"} height={20} />
        </Stack>
      ) : (
        <>
          {banners.length !== 0 ? (
            <Header>
              <Grid
                container
                pt={0.5}
                pb={2}
                px={0}
                lineHeight={1.25}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={12} md={6} xl={7} mb={1} mr={2}>
                  <MDTypography variant="h5" fontWeight="medium" color="black">
                    Banners
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3.8}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  sx={{
                    justifyContent: { xs: "start", md: "end" },
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  <MDButton
                    component={Link}
                    to="/add-banner"
                    // sx={{ marginLeft: 2 }}
                    size="small"
                    color="primary"
                  >
                    Add Banner
                  </MDButton>
                </Grid>
              </Grid>
              <MDBox py={1}>
                <Grid container>
                  <Grid item xs={12} md={12} xl={12}>
                    {renderBanners}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox display="flex" justifyContent="end" alignItems="center" mt={3}>
                {page > 0 ? (
                  <MDButton
                    onClick={() => {
                      if (page > 0) {
                        paginate(-1);
                      }
                    }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Previous Page
                  </MDButton>
                ) : null}
                {page > 0 && nextPage ? (
                  <MDTypography variant="button" color="primary">
                    |
                  </MDTypography>
                ) : null}
                {nextPage ? (
                  <MDButton
                    onClick={() => {
                      if (nextPage) {
                        paginate(1);
                      }
                    }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Next Page
                  </MDButton>
                ) : null}
              </MDBox>
            </Header>
          ) : (
            <Grid my={20}>
              <MDTypography
                variant="text"
                color="text"
                display="flex"
                justifyContent="center"
                mb={2}
              >
                No Banners found !
              </MDTypography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MDButton component={Link} to="/add-banner" size="small" color="primary">
                  Add Banner
                </MDButton>
              </Grid>
            </Grid>
          )}
        </>
      )}

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={5} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Are you sure you want to remove this banner?
            </MDTypography>
            {deleting && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
          <MDBox mt={2} mb={4} display="flex" justifyContent="center">
            <MDBox mr={2}>
              <MDButton
                onClick={handleClose}
                autoFocus
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDButton
              autoFocus
              variant="gradient"
              color="primary"
              onClick={() => {
                deleteBanner();
              }}
              size="small"
            >
              Confirm
            </MDButton>
          </MDBox>
        </Dialog>
      </MDBox>

      <Footer />
      <Snackbar
        open={openTwo}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Removing banner failed!
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Banners;
